
.App {
  font-family: 'Inter', sans-serif; /* Ensure to specify a fallback font */
}
 
body {
  font-family: 'Roboto', sans-serif;
  padding: 0;
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.ql-toolbar.ql-snow {
  background: #ffffff;
  /* border: none !important; */
  border-radius: 8px 8px 0px 0px;
 
  padding: 0px;
  position: relative;
  top: 1px;
  /* height: 45px; */
  display: flex;
  /* gap: 2px;  */
  /* outline: none !important; */
}
.ql-toolbar.ql-snow{
  padding: 2px 0px 8px 1px !important;
}
.ql-container.ql-snow {
  border: none !important;
  outline: none !important;
}
 
.ql-editor {
  font-size: 0.8vw;
  border: 1px solid #ccc;
  border-radius: 0px 0px 8px 8px;
  height: 200px;
  /* padding: 10px; */
}
 
.ql-toolbar svg {
  height: 24px !important;
  width: 24px !important;
  border: 0.5px solid #ccc;
  border-radius: 4px;
  padding: 2px;
 
}
.ql-toolbar button.ql-list svg,.ql-toolbar button.ql-link svg ,.ql-toolbar button.ql-align svg,.ql-toolbar button.ql-indent svg {
  height: 24px !important;
  width: 24px !important;
  border: none;
  border-radius: 4px;
  padding: 2px;
}
.ql-toolbar button.ql-align svg {
  position: relative;
  top: -4px;
  left: -2px;
  padding:3px 2px 2px 1px ;
}
.ql-toolbar span.ql-formats:has(.ql-align){
  border: 1px solid #ccc !important;
  border-radius: 4px; /* Optional: consistent border radius */
  height: 25px !important;
  position: relative;
  padding: 1px 0px 4px 0px;
  top:3px;
  background: none; /* Remove background, if necessary */
}
 
/* Optional: Hover effect for ql-align buttons */
.ql-toolbar button.ql-align:hover {
  background-color: #f0f0f0; /* Subtle hover effect */
}
 
.ql-snow .ql-toolbar button {
  padding: 8px 12px;
  margin: 0; /* Optional: prevent margin if you are using gap on the toolbar */
}
 
.ql-snow .ql-toolbar button svg {
  /* border: 0.5px solid #939393; */
  border-radius: 4px;
  padding: 10px !important;
  height: 18px !important;
  width: 18px !important;
}
.ql-snow .ql-picker.ql-header {
  margin-left: 0.5vw;
  margin-top: 0.2vw; /* Adjust the value as needed */
}
.ql-toolbar button .ql-stroke,
.ql-toolbar button .ql-fill {
  stroke-width: 1.6;
}
.ql-toolbar .ql-snow {
  padding: 0px;
  
}

.ql-toolbar.ql-snow span:first-child span:first-child span:nth-child(1){
  border: 1px solid #ccc;
  position: relative;
  /* top:2px; */
  border-radius: 4px;
  padding-left: 2px !important;
 /* Add a black border to the first span */
}
.ql-toolbar.ql-snow span:first-child span:first-child span:nth-child(1) svg{
  border: 0.5px solid #ccc;
  border-radius: 4px;
  padding: 0px !important;
  margin-right:2px ;
  height: 17px !important;
  width: 17px !important;  
}
 
 
 
/* Optional: Add hover effect to toolbar buttons */
.ql-toolbar button:hover {
  background-color: #ccc; /* Subtle hover effect */
}
.ql-toolbar .ql-link,
.ql-toolbar .ql-list {
  border: none !important;
  /* padding: 6px 8px; */
  border-radius: 4px;
  /* margin: 2px; */
 
}
.ql-toolbar .ql-link button,
.ql-toolbar .ql-list button{
  border: none !important;
  /* padding: 6px 8px; */
  border-radius: 4px;
  /* margin: 2px; */
 
}
.ql-toolbar .ql-link button svg,
.ql-toolbar .ql-list button svg{
  border: none !important;
  padding: 6px 8px;
  border-radius: 4px;
  margin: 2px;  
}
 
/* Target all toolbar icons */
.ql-toolbar .ql-stroke {
  stroke: #667085 !important; /* Change to your desired color */
}
 
.ql-toolbar .ql-fill {
  fill: #667085 !important; /* Change to your desired color */
}
 
/* Optional: Add hover effect to toolbar buttons */
.ql-toolbar button:hover .ql-stroke,
.ql-toolbar button:hover .ql-fill {
  stroke: #000000 !important;
 
}
.ql-toolbar .ql-active .ql-stroke,
.ql-toolbar .ql-active .ql-fill {
  stroke: #000000 !important;
 
}